<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-tariff-archive :tariffsArchive="tariffsArchive" @refresh="refresh"/>
        </div>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent" :name="name"></filter-modalVue>
    </div>
</template>

<script>
import TableTariffArchive from '@/views/component/Table/tableTariffArchive.vue'
    export default {
        props: {
            name: {
                type: String,
                default: '',
            }
        },
        components: {
            TableTariffArchive
        },
        data() {
            return {
                showPreloader: false,
                tariffsArchive: [],
                fields: [
                    { key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
                    { key: 'name', label: 'Имя', sortable: true, thStyle: {width: '40px'}},
                    { key: 'division_base_tariff', label: 'Подразделения', sortable: true, thStyle: {width: '40px'}},
                    { key: 'sort', label: 'Порядок тарифа', sortable: true, thStyle: {width: '40px'}},
                    { key: 'type_tariff_name', label: 'Тип тариф', sortable: true, thStyle: {width: '40px'}},
                ],
            }
        },
        mounted() {
            this.openFilter()
            this.$store.commit('pageData/setdataCount', null)
        },
        methods: {
            refresh(){
                this.showPreloader = true
                this.$http.get(`${this.name}`)
                    .then(res => {
                        this.tariffsArchive = res.data
                        this.$store.commit('pageData/setdataCount', this.tariffsArchive.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            },
            sendToParent(tableData){
                this.tariffsArchive = tableData
                this.$store.commit('pageData/setdataCount', this.tariffsArchive.length)
            },
            openFilter(){
                let allRoutes = this.$store.state.draggableTab.tabs
                let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
                let arrayRoutes = []
                allRoutes.forEach(element => {
                    arrayRoutes.push(element.path)
                });
                if (arrayRoutes.includes(this.$route.path) == true) {
                    if(this.name == 'base-tariffs/archive') {
                        this.showPreloader = true
                        this.$http
                        .get(`${this.name}`, {params: param})
                        .then(res => {
                                this.tariffsArchive = res.data
                                this.$store.commit('pageData/setdataCount', this.tariffsArchive.length)
                                this.showPreloader = false
                                this.$store.commit('REFRESH_DATA', false)
                            })
                    }
                }else if(arrayRoutes.includes(this.$route.path) == false){
                    if(this.name == 'base-tariffs/archive') {
                        this.$bvModal.show(this.$route.name + 'filter')
                    }
                }
            },
        },
        computed: {
            fetchingNewData(){
                return this.$store.state.refresh.fetchingNewData
            }
        },
        watch: {
            fetchingNewData(val){
                if (val) {
                    let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
                    this.$http
                    .get(`${this.name}`, {params: param})
                    .then(res =>{
                        this.tariffsArchive = res.data
                        this.$store.commit('pageData/setdataCount', this.tariffsArchive.length)
                        this.$store.commit('REFRESH_DATA', false)
                    })
                }
            }
        }
    }
</script>